<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<CompanyAssignmentSectionHeader />
		</v-flex>
		<v-flex shrink>
			<v-divider />
		</v-flex>
		<v-flex scroll-y>
			<CompanyAssignmentMatrix />
		</v-flex>
	</v-layout>
</template>

<script>
import CompanyAssignmentModuleGuard from '@/mixins/ModulesGuards/TeamManager/CompanyAssignmentModuleGuard'

export default {
	name: 'CompanyAssignmentSection',
	components: {
		CompanyAssignmentMatrix: () => ({
			component: import('@/components/TeamManager/CompanyAssignmentMatrix')
		}),
		CompanyAssignmentSectionHeader: () => ({
			component: import('@/components/TeamManager/CompanyAssignmentSectionHeader')
		})
	},
	mixins: [CompanyAssignmentModuleGuard],
	watch: {
		accountingFirmId: {
			handler: function () {
				this.service.init()
			},
			immediate: true
		}
	}
}
</script>
