// ============================================================================
// CompanyAssignmentService
// ------------------------
// CompanyAssignment module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/TeamManagerApi'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

// ---------
// Internals
// ---------
const Private = {
	dispatch: (name, data) => store.dispatch(`teamManager/companyAssignment/${name}`, data),
	getCancelToken: function (tokenName) {
		return store.state.teamManager.companyAssignment.cancelTokens[tokenName]
	},
	setCancelToken: function (cancelTokenValue) {
		return Private.dispatch('setCancelToken', cancelTokenValue)
	},
	clearUsers: function () {
		return Private.dispatch('clearUsers')
	},
	setUsers: function (users) {
		return Private.dispatch('setUsers', users)
	},
	showSuccess: function (message) {
		AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, message)
	},
	getSearchedUsers: function () {
		return Private.getFilters().users
	},
	getSearchedText: function () {
		return Private.getFilters().search
	},
	getFilters: function () {
		return store.state.teamManager.companyAssignment.filters
	},
	showCompaniesAssignmentInProgress: function () {
		Private.showSuccess(window.vueInstance.$t('team-manager.companies.assignment_in_progress'))
	},
	showCompaniesDecommissioningInProgress: function () {
		Private.showSuccess(window.vueInstance.$t('team-manager.companies.decommissioning_in_progress'))
	}
}

// -------
// Exports
// -------
export default {
	init: function () {
		return Private.clearUsers()
			.then(() => {
				const accountingFirmId = store.state.accountingFirm?.selected?.id
				return API.getAccountants(accountingFirmId)
			})
			.then(res => res.data.data)
			.then(users => users.map(user => ({ email: user.email, id: user.id, status: user.customers_status, username: user.username })))
			.then(users => {
				return Private.setUsers(users)
			})
	},
	changeUsersCompanyAssignment: function (vendorId, toggleValue) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const params = {
			users: Private.getSearchedUsers()
		}

		if (toggleValue === 'all') {
			return API.addUsersToCompany(accountingFirmId, vendorId, params).then(() => {
				Private.showCompaniesAssignmentInProgress()
			})
		} else {
			return API.removeUsersFromCompany(accountingFirmId, vendorId, params).then(() => {
				Private.showCompaniesDecommissioningInProgress()
			})
		}
	},
	changeUserCompaniesAssignment: function (userId, toggleValue) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const params = {
			q: Private.getSearchedText()
		}
		if (toggleValue === 'all') {
			return API.addUserToCompanies(accountingFirmId, userId, params).then(() => {
				Private.showCompaniesAssignmentInProgress()
			})
		} else {
			return API.removeUserFromCompanies(accountingFirmId, userId, params).then(() => {
				Private.showCompaniesDecommissioningInProgress()
			})
		}
	},
	changeUserCompanyAssignment: function (vendorId, userId, toggleValue) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.updateAccountantAccess(accountingFirmId, userId, {
			access: toggleValue,
			company_id: vendorId
		}).then(() => {
			if (toggleValue) {
				Private.showCompaniesAssignmentInProgress()
			} else {
				Private.showCompaniesDecommissioningInProgress()
			}
		})
	},
	listCompanies: function (paginationData) {
		const accountingFirmId = store.state.accountingFirm.selected?.id
		const callAnswer = API.getClients(
			accountingFirmId,
			{
				page: paginationData.page,
				po: paginationData.rowsPerPage,
				q: Private.getSearchedText(),
				users: Private.getSearchedUsers()
			},
			Private.getCancelToken('listCompanies')
		)
		Private.setCancelToken({ cancelToken: callAnswer.cancelToken, item: 'listCompanies' })
		return callAnswer.promise
			.then(res => res.data)
			.then(({ data, pagination }) => ({
				companies: data.map(company => ({
					id: company.id,
					name: company.pivot.client_code ? `${company.company} (${company.pivot.client_code})` : company.company,
					status: company.accountants_status,
					users: company.accountants.map(user => user.id)
				})),
				total: pagination.total
			}))
	},
	getFilters: Private.getFilters,
	getSearchedText: Private.getSearchedText,
	setSearchedText: function (searchedText) {
		let newFilters = { ...Private.getFilters() }
		newFilters.search = searchedText
		return Private.dispatch('setFilters', newFilters)
	},
	getSearchedUsers: Private.getSearchedUsers,
	setSearchedUsers: function (searchedUsers) {
		let newFilters = { ...Private.getFilters() }
		newFilters.users = searchedUsers
		return Private.dispatch('setFilters', newFilters)
	},
	getUsers: function () {
		return store.state.teamManager.companyAssignment.users
	},
	rights: {
		canCheck: function () {
			const accountingFirm = store.state.accountingFirm?.selected
			return accountingFirm.isAccountantAdmin
		}
	}
}
