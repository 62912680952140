import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/TeamManager/CompanyAssignmentService'
import { Events, Bus } from '@/events/TeamManager/CompanyAssignmentEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: '',
			eventBus: Bus,
			service: Service,
			events: Events
		}
	},
	computed: {
		hasModule: function () {
			return true
		}
	}
}
